.views-field-field-thrombosis-image{
  text-align: center;
}

.gallery-title{
  text-align: center;
}

/*.gridborder{
  border: 1px solid $wcm-border-gray;
  height: 520px;
  padding: 20px 20px 20px 20px;
  text-align: center;
}*/

.gallery_row{
  border: 1px solid $wcm-border-gray;
  height: 520px;
  padding: 20px 20px 20px 20px;
  text-align: center;
  }

.physician_row{
  border: 1px solid $wcm-border-gray;
  height: 520px;
}

.gallery-images{
  border-radius: 15px;
}