.front .site-main .fieldable-panels-pane .external-link:after {
  content: '';
  display: block;
}

.thumbnail-controller {
  display: none;
}

.left {
  text-align: left;
}

.welcome-image {
  text-align: left;
  margin: 5px 15px 0px 0px;
  width: 12%;
  min-width: 50px;
  float: left;
}

.news-teaser-image {
  text-align: left;
  margin: 5px 10px 0px 0px;
  width: 25%;
  min-width: 50px;
  float: left;
}

.news-image {
  margin: 5px 20px 0px 0px;
  float: left;
}

$two-col-margin-left: 2% 2% 0% .10%;
$two-col-margin-right: 2% .10% 0% 0%;

/*ROW2*/
@mixin row2-dim {
  width: 48.9%;
  height: 250px;
  padding: 0% 2% 1% 2%;
  text-align: left;
  overflow: hidden;
  display: block;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

.row2-left {
  @include row2-dim;
  float: left;
  clear: left;
  margin: $two-col-margin-left;
}

.row2-right {
  @include row2-dim;
  float: right;
  clear: right;
  margin: $two-col-margin-right;
}

/*COLUMNS*/
$three-col-margin-left: 2% 2% 0% .10%;
$three-col-margin-center: 2% 0% 0% 0%;
$three-col-margin-right: 2% .10% 0% 2%;
$three-col-width: 31.9%;

/*ROW3*/

@mixin row3-dim {
  width: $three-col-width;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

.row3-left {
  @include row3-dim;
  clear: left;
  margin: $three-col-margin-left;
}

.row3-center {
  @include row3-dim;
  margin: $three-col-margin-center;
}

.row3-right {
  @include row3-dim;
  margin: $three-col-margin-right;
}


/*ROW4*/
@mixin row4-dim {
  width: $three-col-width;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid $wcm-border-gray;
  box-shadow: 1px 1px 10px #888888;
}

.row4-left {
  @include row4-dim;
  clear: left;
  margin: $three-col-margin-left;
}

.row4-center {
  @include row4-dim;
  position: static;
  margin: $three-col-margin-center;
}

.row4-right {
  @include row4-dim;
  margin: $three-col-margin-right;
}

@media (max-width : 991px) {
  /* ROW2*/

  .row2-left,
  .row2-right {
    width: 100%;
    height: 100%;
    min-height: 300px;
    max-height: 300px;
    float: left;
    margin: 0px;
    border: 1px solid $wcm-border-gray;
    box-shadow: 0px 0px 0px 0px $wcm-white;
  }

  /*PORTRAIT ROW3*/
  .row3-left,
  .row3-center,
  .row3-right {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px;
    border: 1px solid $wcm-border-gray;
    box-shadow: 0px 0px 0px 0px $wcm-white;
  }

  /*PORTRAIT ROW4*/
  .row4-left,
  .row4-center,
  .row4-right {
    width: 100%;
    height: 35px;
    margin: 0px;
    border: 1px solid $wcm-border-gray;
    box-shadow: 0px 0px 0px 0px $wcm-white;
  }

  .gridborder {
    border: 1px solid $wcm-border-gray;
    padding: 10px;
    text-align: center;
    height: auto !important
  }

  #drawer-nav .level-2 {
    width: 100% !important;
    padding: 0px 20px 0px 0px;
  }

}
