/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/*LOGO*/
.brand__lockup img {
  height: 84px; }

/*FOOTER CONTACT US*/
@media screen and (min-width: 768px) {
  .footer-contact__contact-us a {
    padding: 20px 38px; } }

/*LEVEL 1 DRAWER NAV OVERRIDE*/
#primary-nav .level-1 {
  width: 16.6%;
  padding: 8px 0px; }

/*LEVEL 2 DRAWER NAV OVERRIDE*/
#drawer-nav .level-2 {
  width: 16% !important;
  padding: 0px 20px 0px 0px; }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

.front .site-main .fieldable-panels-pane .external-link:after {
  content: '';
  display: block; }

.thumbnail-controller {
  display: none; }

.left {
  text-align: left; }

.welcome-image {
  text-align: left;
  margin: 5px 15px 0px 0px;
  width: 12%;
  min-width: 50px;
  float: left; }

.news-teaser-image {
  text-align: left;
  margin: 5px 10px 0px 0px;
  width: 25%;
  min-width: 50px;
  float: left; }

.news-image {
  margin: 5px 20px 0px 0px;
  float: left; }

/*ROW2*/
.row2-left {
  width: 48.9%;
  height: 250px;
  padding: 0% 2% 1% 2%;
  text-align: left;
  overflow: hidden;
  display: block;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  float: left;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row2-right {
  width: 48.9%;
  height: 250px;
  padding: 0% 2% 1% 2%;
  text-align: left;
  overflow: hidden;
  display: block;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  float: right;
  clear: right;
  margin: 2% 0.1% 0% 0%; }

/*COLUMNS*/
/*ROW3*/
.row3-left {
  width: 31.9%;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row3-center {
  width: 31.9%;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0% 0% 0%; }

.row3-right {
  width: 31.9%;
  height: 260px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0.1% 0% 2%; }

/*ROW4*/
.row4-left {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  clear: left;
  margin: 2% 2% 0% 0.1%; }

.row4-center {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  position: static;
  margin: 2% 0% 0% 0%; }

.row4-right {
  width: 31.9%;
  height: 100px;
  min-height: 100px;
  padding: 0% 2% 1% 2%;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  float: left;
  border: 1px solid #dddddd;
  box-shadow: 1px 1px 10px #888888;
  margin: 2% 0.1% 0% 2%; }

@media (max-width: 991px) {
  /* ROW2*/
  .row2-left,
  .row2-right {
    width: 100%;
    height: 100%;
    min-height: 300px;
    max-height: 300px;
    float: left;
    margin: 0px;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  /*PORTRAIT ROW3*/
  .row3-left,
  .row3-center,
  .row3-right {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0px;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  /*PORTRAIT ROW4*/
  .row4-left,
  .row4-center,
  .row4-right {
    width: 100%;
    height: 35px;
    margin: 0px;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 0px 0px #fff; }
  .gridborder {
    border: 1px solid #dddddd;
    padding: 10px;
    text-align: center;
    height: auto !important; }
  #drawer-nav .level-2 {
    width: 100% !important;
    padding: 0px 20px 0px 0px; } }

.views-field-field-thrombosis-image {
  text-align: center; }

.gallery-title {
  text-align: center; }

/*.gridborder{
  border: 1px solid $wcm-border-gray;
  height: 520px;
  padding: 20px 20px 20px 20px;
  text-align: center;
}*/
.gallery_row {
  border: 1px solid #dddddd;
  height: 520px;
  padding: 20px 20px 20px 20px;
  text-align: center; }

.physician_row {
  border: 1px solid #dddddd;
  height: 520px; }

.gallery-images {
  border-radius: 15px; }
